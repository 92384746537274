import { ENUMS } from '../enums';

export const QRCODE = [
  {
		key: 'qrCode',
		description: 'QR Code',
		example: '',
		type: 'String',
	},
	{
		key: 'receivingQr',
		description: 'QR Code',
		example: '',
		type: 'String',
	},
  {
		key: 'qrcodeCategory',
		description: 'QR Code Category',
		example: '',
		enum: ENUMS.qrcodeCategoryEnum,
		type: 'String',
	},
	{
		key: 'qrcodeState',
		description: 'QR Code State',
		example: '',
		enum: ENUMS.qrcodeStateEnum,
		type: 'String',
	},
  {
		key: 'payloadFormatIndicator',
		description: 'Defines the Version of the QR Code Template and Hence the Conventions on Identification, Length, and Value',
		example: '01',
		enum: ENUMS.payloadFormatIndicator,
		type: 'String',
	},
	{
		key: 'pointofInitiationMethod',
		description: 'Identifies the Communication Technology (QR Code) Whether the Data is Static or Dynamic',
		example: '12',
		enum: ENUMS.pointofInitiationMethodEnum,
		type: 'String',
	},
  {
		key: 'barcodeCpqrcPayload',
		description: 'Bar Code (Domestic)',
		example: '',
		type: 'String',
	},
	{
		key: 'barcodeCpqrcPayloadtxnID',
		description: 'Bar Code Serial Number',
		example: '',
		type: 'String',
	},
  {
		key: 'emvCpqrcPayload',
		description: 'QR Code (Overseas)',
		example: '',
		type: 'String',
	},
	{
		key: 'emvCpqrcPayloadtxnID',
		description: 'QR Code Serial Number',
		example: '',
		type: 'String',
	},
  {
		key: 'receivingQR',
		description: 'Receiving QR',
		example: '',
		type: 'String',
	},
	{
		key: 'supportedQrCategories',
		description: 'Supported QR code types',
		example: '',
		type: 'Object',
		attributes: ['khqr', 'upiChinaBackend', 'upiChinaFrontend', 'upiEmv'],
	},
	{
		key: 'khqr',
		description: 'Supports KHQR (Cambodian QR Code)',
		example: '',
		type: 'String',
	},
	{
		key: 'upiChinaBackend',
		description: 'Supports domestic UPI transactions',
		example: '',
		type: 'String',
	},
	{
		key: 'upiChinaFrontend',
		description: 'QR code is an aggregate code',
		example: '',
		type: 'String',
	},
	{
		key: 'upiEmv',
		description: 'Supports international UPI',
		example: '',
		type: 'String',
	},
];