import { HTTP_METHODS, TAG } from '@/const';

export const ACCOUNT_CONFIGURATION = [
	{
		id: '3201',
		title: 'Freeze an Account',
		titleCN: '账号冻结',
		endpoint: '/app/applyFreezeAccount',
		method: HTTP_METHODS.POST,
		tag: TAG.ACCOUNT_CONFIGURATION,
		attributes: ['acctId', 'custId', 'prdtNo'],
		respAttributes: ['acctId'],
	},
	{
		id: '3202',
		title: 'Unfreeze an Account',
		titleCN: '账号解冻',
		endpoint: '/app/applyUnfreezeAccount',
		method: HTTP_METHODS.POST,
		tag: TAG.ACCOUNT_CONFIGURATION,
		attributes: ['acctId', 'custId', 'prdtNo'],
		respAttributes: ['acctId'],
	},
	{
		id: '3203',
		title: 'Close an Account',
		titleCN: '账号注销',
		endpoint: '/app/accountClose',
		method: HTTP_METHODS.POST,
		tag: TAG.ACCOUNT_CONFIGURATION,
		attributes: ['acctId', 'custId', 'prdtNo'],
		respAttributes: ['acctId'],
	},
];
