export const INCLUDE_REQUEST_ATTRIBUTES = [
	{
		key: 'orgId',
		description: 'Organization ID (Unique to Every Co-brand)',
		descriptionCN: '机构ID [每个机构都是独特]',
		type: 'String',
		required: true,
	},
	{
		key: 'sign',
		description: 'Signature of Request (Unique to Every Request)',
		descriptionCN: '签名值 [每个请求都是独特]',
		type: 'String',
		required: true,
	},
	{
		key: 'timestamp',
		description: 'Timestamp of Request',
		descriptionCN: '请求时间',
		type: 'String',
		required: true,
	},
	{
		key: 'traceId',
		description: 'Unique Identifier for Request',
		descriptionCN: '请求流水ID',
		type: 'String',
		required: true,
	},
	{
		key: 'verNo',
		description: "Core System's API Version",
		descriptionCN: '版本号',
		type: 'String',
		required: true,
	},
];

export const INCLUDE_RESPONSE_ATTRIBUTES = [
	{
		key: 'orgId',
		description: 'Organization ID (Unique to Every Co-brand)',
		descriptionCN: '机构ID [每个机构都是独特]',
		type: 'String',
	},
	{
		key: 'respCode',
		description: 'Response Code',
		descriptionCN: '响应码',
		type: 'String',
	},
	{
		key: 'respMsg',
		description: 'Response Message',
		descriptionCN: '响应信息',
		type: 'String',
	},
	{
		key: 'sign',
		description: 'Signature of Request (Unique to Every Request)',
		descriptionCN: '签名值 [每个请求都是独特]',
		type: 'String',
	},
	{
		key: 'timestamp',
		description: 'Timestamp of Request',
		descriptionCN: '请求时间',
		type: 'String',
	},
	{
		key: 'traceId',
		description: 'Unique Identifier for Request',
		descriptionCN: '请求流水ID',
		type: 'String',
	},
	{
		key: 'verNo',
		description: "Core System's API Version",
		descriptionCN: '版本号',
		type: 'String',
	},
];
