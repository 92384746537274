import { ENUMS } from '../enums';
import { VALUES } from '../values';

export const STATUS = [
	{
		key: 'acctSta',
		description: '账户状态',
		example: '00',
		enum: ENUMS.accountStatusEnum,
		type: 'String',
	},
	{
		key: 'acctStaArray',
		description: '账户状态',
		example: '',
		enum: ENUMS.accountStatusEnum,
		type: 'Array',
		attributes: [],
	},
	{
		key: 'accountStatus',
		description: '账户状态',
		example: '',
		type: 'String',
	},
	{
		key: 'cardSta',
		description: '卡状态',
		example: '0',
		enum: ENUMS.cardStatusEnum,
		type: 'String',
	},
	{
		key: 'cardStaArray',
		description: '卡状态',
		example: '',
		enum: ENUMS.cardStatusEnum,
		type: 'Array',
		attributes: [],
	},
	{
		key: 'txnStaCd',
		description: '交易状态码',
		example: '2',
		enum: ENUMS.transactionStatusCodeEnum,
		type: 'String',
	},
	{
		key: 'sta',
		description: '申请状态',
		example: '01',
		type: 'String',
		enum: ENUMS.applicationStatusEnum,
	},
	{
		key: 'allocateStat',
		description: '审核标识',
		example: '1',
		enum: ENUMS.allocatedStatusEnum,
		type: 'String',
	},
	{
		key: 'paymentStatus',
		description: '支付状态',
		example: '',
		type: 'String',
	},
	{
		key: 'state',
		description: '订单状态',
		example: '0',
		enum: ENUMS.stateEnum,
		type: 'String',
	},
	{
		key: 'kycStatus',
		description: 'KYC状态',
		example: '',
		type: 'String',
	},
	{
		key: 'upiState',
		description: '开通移动支付状态',
		example: '0',
		enum: ENUMS.upiStateEnum,
		type: 'String',
	},
	{
		key: 'keyStatus',
		description: '账户/卡状态',
		example: '',
		enum: ENUMS.keyStatusEnum,
		type: 'String',
	},
	{
		key: 'status',
		description: '交易状态',
		example: '',
		enum: ENUMS.transactionStatusEnum,
		value: VALUES.preauthorizationStatusValue,
		type: 'String',
	},
	{
		key: 'curCardStatus',
		description: '目前的卡状态',
		example: '0',
		enum: ENUMS.cardStatusEnum,
		type: 'String',
	},
	{
		key: 'prevCardStatus',
		description: '之前的卡状态',
		example: '1',
		enum: ENUMS.cardStatusEnum,
		type: 'String',
	},
];
