import { HTTP_METHODS, TAG } from '@/const';

export const BAKONG_TRANSACTION = [
	{
		id: '4211',
		title: 'Query Bakong Account',
		titleCN: '查询Bakong账号',
		endpoint: '/app/bakongQueryBakongAccount',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: ['mobile'],
		respAttributes: [
			'accountId',
			'accountName',
			'accountStatus',
			'bankName',
			'frozen',
			'kycStatus',
			'nameDec',
			'nameEnc',
			'phone',
		],
	},
	{
		id: '4212',
		title: 'Bakong Top Up Wallet',
		titleCN: 'Bakong钱包充值',
		endpoint: '/app/bakongTopUpWallet',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: [
			'acctIdOUT',
			'assetId',
			'custId',
			'destAccountId',
			'destAccountName',
			'destBankCode',
			'destBankName',
			'note',
			'prdtNoOUT',
			'qrCode',
			'txnAt',
		],
		optionalAttributes: ['destAccountName', 'destBankCode', 'destBankName', 'note', 'qrCode', 'prdtNoOUT'],
		respAttributes: [
			'acctIdOUT',
			'acctNmOUT',
			'assetId',
			'currAvailAtOUT',
			'currBalAtOUT',
			'destAccountId',
			'destAccountName',
			'destBankName',
			'fee',
			'feeCurrency',
			'intTxnRefId',
			'intTxnSeqId',
			'lockAtOUT',
			'mobileOUT',
			'note',
			'qrCode',
			'originalTxnAmt',
			'originalTxnAt',
			'originalTxnCurrency',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'srcBankName',
			'state',
			'txnAt',
			'txnDt',
			'txnHash',
			'txnRefId',
			'txnSeqId',
			'txnTm',
			'txnType',
		],
	},
	{
		id: '4221',
		title: 'Check Bakong Bank List',
		titleCN: '查询Bakong银行列表',
		endpoint: '/app/bakongQueryBankList',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: [],
		respAttributes: [
			'bankList'
		],
	},
	{
		id: '4222',
		title: 'Bakong Query Other Bank Accounts',
		titleCN: 'Bakong查询其他银行账号',
		endpoint: '/app/bakongQueryOtherBankAccount',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: ['keyId', 'participantCode'],
		respAttributes: [
			'account',
			'account_currency',
			'account_name',
			'account_type',
			'bank_code',
			'kycStatus',
		],
	},
	{
		id: '4223',
		title: 'Transfer to Account of Bakong Member Bank',
		titleCN: '转账至Bakong会员银行的账号',
		endpoint: '/app/bakongMemberTransfer',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: [
			'acctIdOUT',
			'assetId',
			'custId',
			'destAccountId',
			'destAccountName',
			'destBankCode',
			'destBankName',
			'note',
			'prdtNoOUT',
			'txnAt',
		],
		optionalAttributes: ['note', 'prdtNoOUT'],
		respAttributes: [
			'acctIdOUT',
			'acctNmOUT',
			'assetId',
			'currAvailAtOUT',
			'currBalAtOUT',
			'destAccountId',
			'destAccountName',
			'destBankCode',
			'destBankName',
			'fee',
			'feeCurrency',
			'intTxnRefId',
			'intTxnSeqId',
			'lockAtOUT',
			'mobileOUT',
			'note',
			'qrCode',
			'originalTxnAmt',
			'originalTxnAt',
			'originalTxnCurrency',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'srcBankName',
			'state',
			'txnAt',
			'txnDt',
			'txnHash',
			'txnRefId',
			'txnTm',
			'txnType',
		],
	},
	{
		id: '4224',
		title: 'Query Bakong Transaction Status',
		titleCN: 'bakong交易状态查询',
		endpoint: '/app/bakongOrderQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: ['txnRefId', 'type'],
		optionalAttributes: ['type'],
		respAttributes: ['state'],
	},
	{
		id: '4225',
		title: 'Query Bakong Exchange Rate',
		titleCN: 'bakong汇率牌价查询',
		endpoint: '/app/bakongExchangeRateQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: ['dstCurrency', 'srcCurrency'],
		respAttributes: ['buyRate', 'dstCurrency', 'sellRate', 'srcCurrency', 'sta'],
	},
	{
		id: '4226',
		title: 'Calculate Settlement with Bakong',
		titleCN: '计算bakong结算信息',
		endpoint: '/app/calculateSettlementWithBakong',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: ['cardIdEnc', 'qrCode', 'txnAmt', 'txnCurrency'],
		optionalAttributes: ['txnAmt', 'txnCurrency'],
		respAttributes: [
			'qrCode',
			'settleWithBakongAmt',
			'settleWithBakongCurrency',
			'txnAmt',
			'txnCurrency',
		],
	},
	{
		id: '4231',
		title: 'Decode KHQR',
		titleCN: '解码KHQR',
		endpoint: '/app/bakongDecodeKHQR',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: ['qrCode'],
		respAttributes: [
			'accountInformation',
			'acquiringBank',
			'bakongAccountID',
			'countryCode',
			'crc',
			'merchantCategoryCode',
			'merchantCity',
			'merchantID',
			'merchantName',
			'merchantType',
			'payloadFormatIndicator',
			'pointofInitiationMethod',
			'timestamp',
			'transactionAmount',
			'transactionCurrency',
		],
	},
	{
		id: '4232',
		title: 'Verify KHQR',
		titleCN: '验证KHQR',
		endpoint: '/app/bakongVerifyKHQR',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: ['qrCode'],
		respAttributes: ['isValid'],
	},
	{
		id: '4233',
		title: 'Generate Individual KHQR',
		titleCN: '生成KHQR',
		endpoint: '/app/bakongGenIndividualKHQR',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: ['acctId', 'acctNm', 'amount', 'location', 'txnCurrency'],
		optionalAttributes: ['amount', 'location', 'txnCurrency', 'acctNm'],
		respAttributes: [],
	},
	{
		id: '4241I',
		title: 'Bakong Acknowledge Transaction',
		titleCN: 'Bakong确认交易',
		endpoint: '/app/bakongAcknowledgeTransaction',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: ['metaData', 'txnRefId'],
		optionalAttributes: ['metaData', 'txnRefId'],
		respAttributes: [],
	},
	{
		id: '4242I',
		title: 'Cancel Bakong Transfer',
		titleCN: '取消Bakong转账',
		endpoint: '/app/bakongRefundTransaction',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: ['txnRefId'],
		respAttributes: ['state'],
	},
	{
		id: '4251I',
		title: 'Transfer to Bongloy Account',
		titleCN: '转账转进Bongloy账号',
		endpoint: '/app/bakongIncomingTransaction',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: [
			'acctIdOUT',
			'acctNmOUT',
			'assetId',
			'bankCodeOUT',
			'bankNmOUT',
			'keyIdIN',
			'mobileOUT',
			'note',
			'prdtNoIN',
			'qrCode',
			'txnAt',
			'txnHash',
			'type',
		],
		optionalAttributes: [
			'acctIdOUT',
			'acctNmOUT',
			'bankCodeOUT',
			'bankNmOUT',
			'mobileOUT',
			'note',
			'txnHash',
			'type',
			'qrCode',
			'prdtNoIN'
		],
		respAttributes: ['state', 'txnRefId', 'txnType'],
	},
	{
		id: '4253',
		title: 'Generate Merchant KHQR',
		titleCN: '生成商户KHQR',
		endpoint: '/app/bakongGenMerchantKHQR',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: [
			'billNumber',
			'location',
			'merchantId',
			'merchantNm',
			'storeLabel',
			'terminalLabel',
			'txnAmt',
			'txnCurrency',
		],
		optionalAttributes: [
			'billNumber',
			'location',
			'storeLabel',
			'terminalLabel',
			'txnAmt',
			'txnCurrency',
		],
		respAttributes: ['qrCode'],
	},
	{
		id: '4254',
		title: 'Query Bakong Account by Account ID',
		titleCN: 'Bakong查询账户信息',
		endpoint: '/app/bakongQueryBakongAccountByAccountId',
		method: HTTP_METHODS.POST,
		tag: TAG.BAKONG_TRANSACTION,
		attributes: ['bakongAcctId'],
		respAttributes: [
			'accountId',
			'accountName',
			'accountStatus',
			'bankName',
			'email',
			'frozen',
			'kycStatus',
			'nameDec',
			'nameEnc',
			'phone',
		],
	},
];
