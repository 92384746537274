<template>
	<div v-if="!isNotification" class="content">
		<div class="side left">
			<p class="title" style="margin-top: 20px">
				{{ currentLanguage == 'en' ? title : currentLanguage == 'cn' && titleCN }}
			</p>
			<div v-if="endpoint.tag.id !== 'NTF'" class="item">
				<div class="row">
					<p class="key">data</p>
					<p class="type">Object</p>
				</div>
				<p class="description"></p>
			</div>
			<div v-for="attri in endpointAttri" :key="attri">
				<div :class="`item ${endpoint.tag.id !== 'NTF' && 'i1'}`">
					<div class="row">
						<p
							:class="`key ${
								contentType === 'request' &&
								(endpoint.optionalAttributes && endpoint.optionalAttributes.indexOf(attri.key) != -1
									? ''
									: 'required')
							}`">
							{{ attri.key === 'cardStaArray' ? 'cardSta' : attri.key === 'acctStaArray' ? 'acctSta' : attri.key }}
						</p>
						<p class="type">{{ attri.type }}</p>
						<p class="enc" v-if="attri.encrypted || endpoint.tag.id === 'NTF'">(Encrypted)</p>
					</div>
					<p class="description">{{ attri.description }}</p>
					<div class="enum" v-if="attri.enum">
						<p>Enum:</p>
						<div class="list-item" v-for="e in Object.keys(attri.enum)" :key="e">
							<p v-if="typeof attri.enum[e] == 'string'">
								{{ `- ${e}: ${attri.enum[e]}` }}
							</p>
							<div v-if="typeof attri.enum[e] == 'object'">
								{{ `- ${e}:` }}
								<div class="list-item" v-for="ee in Object.keys(attri.enum[e])" :key="ee">
									<p v-if="typeof attri.enum[e][ee] == 'string'" style="margin-left: 10px">
										{{ `- ${ee}: ${attri.enum[e][ee]}` }}
									</p>
									<div v-if="typeof attri.enum[e][ee] == 'object'" style="margin-left: 10px">
										{{ `- ${ee}:` }}
										<div class="list-item" v-for="eee in Object.keys(attri.enum[e][ee])" :key="eee">
											<p style="margin-left: 10px">
												{{ `- ${eee}: ${attri.enum[e][ee][eee]}` }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="value" v-if="attri.value">
						<p>Value:</p>
						<div class="list-item" v-for="v in attri.value" :key="v">
							{{ `- ${v}` }}
						</div>
					</div>
				</div>
				<div v-if="attri.type == 'Array' || attri.type == 'Object'">
					<div class="item i2" v-for="innerAttr in attri.attri" :key="innerAttr">
						<div class="row">
							<p :class="`key ${
								contentType === 'request' &&
								(attri.requireAttributes && 
								attri.requireAttributes.indexOf(innerAttr.key) != -1 
								? 'required' : '')
							}`">{{ innerAttr.key }}</p>
							<p class="type">{{ innerAttr.type }}</p>
							<p class="enc" v-if="innerAttr.encrypted">(Encrypted)</p>
						</div>
						<p class="description">{{ innerAttr.description }}</p>
						<div class="enum" v-if="innerAttr.enum">
							<p>Enum:</p>
							<div class="list-item" v-for="e in Object.keys(innerAttr.enum)" :key="e">
								<p v-if="typeof innerAttr.enum[e] == 'string'">
									{{ `- ${e}: ${innerAttr.enum[e]}` }}
								</p>
								<div v-if="typeof innerAttr.enum[e] == 'object'">
									{{ `- ${e}:` }}
									<div class="list-item" v-for="ee in Object.keys(innerAttr.enum[e])" :key="ee">
										<p v-if="typeof innerAttr.enum[e][ee] == 'string'" style="margin-left: 10px">
											{{ `- ${ee}: ${innerAttr.enum[e][ee]}` }}
										</p>
										<div v-if="typeof innerAttr.enum[e][ee] == 'object'" style="margin-left: 10px">
											{{ `- ${ee}:` }}
											<div
												class="list-item"
												v-for="eee in Object.keys(innerAttr.enum[e][ee])"
												:key="eee">
												<p style="margin-left: 10px">
													{{ `- ${eee}: ${innerAttr.enum[e][ee][eee]}` }}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="value" v-if="innerAttr.value">
							<p>Value:</p>
							<div class="list-item" v-for="v in innerAttr.value" :key="v">
								{{ `- ${v}` }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="endpoint.tag.id !== 'NTF'">
				<div class="item" v-for="include in includeAttri" :key="include">
					<div class="row">
						<p
							:class="{
								key: true,
								required: contentType === 'request' && 
									(!endpoint.optionalRequestScaffold || !endpoint.optionalRequestScaffold.includes(include.key))
							}">
							{{ include.key }}
						</p>
						<p class="type">{{ include.type }}</p>
					</div>
					<p class="description" v-if="include.description == ''">
						{{
							currentLanguage == 'en'
								? 'Description Not Available!'
								: currentLanguage == 'cn' && '描述不可用!'
						}}
					</p>
					<p class="description" v-if="include.description != ''">
						{{
							currentLanguage == 'en'
								? include.description
								: currentLanguage == 'cn' && include.descriptionCN
						}}
					</p>
				</div>
			</div>
		</div>

		<div class="side right">
			<p class="title" style="margin-top: 20px">
				{{ currentLanguage == 'en' ? exampleTitle : currentLanguage == 'cn' && exampleTitleCN }}
			</p>
			<pre class="prettyprint">{{
				endpoint.tag.id !== 'NTF'
					? getJSON(endpointAttri, contentType, false)
					: getJSON(endpointAttri, contentType, true)
			}}</pre>
		</div>
	</div>
</template>

<script>
export default {
	name: 'EndpointContent',
	props: {
		contentType: String,
		title: String,
		titleCN: String,
		exampleTitle: String,
		exampleTitleCN: String,
		endpoint: {
			type: Object,
			required: true,
			default: () => {},
		},
		endpointAttri: {
			type: Array,
			required: true,
			default: () => [],
		},
		includeAttri: {
			type: Array,
			required: true,
			default: () => [],
		},
		currentLanguage: {
			type: String,
			required: true,
			default: 'en',
		},
	},
	computed: {
		scaffolds() {
			return this.$store.state.client.scaffolds.scaffolds;
		},
		isNotification() {
			if (this.contentType === 'request' && this.endpoint.tag.id === 'NTF') {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		getJSON(data, type, isNotifcation) {
			if (!data) {
				return `"No response provided in config"`;
			}

			const requestJSONScaffold =
				require(`@/resources/${this.$route.params.version}/scaffolds`).REQUEST_JSON_SCAFFOLD;
			const responseJSONScaffold =
				require(`@/resources/${this.$route.params.version}/scaffolds`).RESPONSE_JSON_SCAFFOLD;
			const json =
				type == 'request' ? requestJSONScaffold : type == 'response' && responseJSONScaffold;

			json.data = Object.fromEntries(
				data.map((e) => {
					if (e.key == 'cardStaArray') {
						return ['cardSta', e.example || []];
					}
					if (e.key == 'acctStaArray') {
						return ['acctSta', e.example || []];
					}
					
					if (e.type == 'Array' && e.subType == null) {
						return [e.key, e.attributes];
					}

					if (e.type == 'Object') {
						return [
							e.key,
							Object.fromEntries(
								e.attributes.map((e) => {
									return [e, ''];
								})
							),
						];
					}

					if (e.type == 'Array' && e.subType == 'Object') {
						return [
							e.key,
							[
								Object.fromEntries(
									e.attri.map((e) => {
										if (e.type == 'Array' && e.subType == null) {
											return [e.key, e.attributes];
										}

										if (e.type == 'Array' && e.subType == 'Object') {
											return [
												e.key,
												[
													Object.fromEntries(
														e.attri.map((e) => {
															return [e.key, e.example.replace(/\s{2}/, ' ')];
														})
													),
												],
											];
										}

										return [e.key, e.example.replace(/\s{2}/, ' ')];
									})
								),
							],
						];
					}

					if (e.type == 'Boolean') {
						return [ e.key, e.example || false ];
					}

					return [e.key, e.example.replace(/\s{2}/, ' ')];
				})
			);

			if (isNotifcation) {
				return JSON.stringify(json.data, null, 2);
			} else {
				return JSON.stringify(json, null, 2);
			}
		},
	},
};
</script>
