import { ENUMS } from '../enums';
import { VALUES } from '../values';

export const TRANSACTION = [
	{
		key: 'intTxnRefId',
		description: '交易参考号',
		example: '',
		type: 'String',
	},
	{
		key: 'intTxnSeqId',
		description: '交易流水号',
		example: '',
		type: 'String',
	},
	{
		key: 'txnRefId',
		description: '交易参考号',
		example: '',
		type: 'String',
	},
	{
		key: 'txnSeqId',
		description: '交易流水号',
		example: '',
		type: 'String',
	},
	{
		key: 'txnDt',
		description: '交易日期',
		example: '',
		type: 'String',
	},
	{
		key: 'txnDate',
		description: '交易日期',
		example: '',
		type: 'String',
	},
	{
		key: 'txnTm',
		description: '交易日期',
		example: '',
		type: 'String',
	},
	{
		key: 'txnTime',
		description: '交易日期',
		example: '',
		type: 'String',
	},
	{
		key: 'lastTxnDt',
		description: '最后交易日期',
		example: '',
		type: 'String',
	},
	{
		key: 'txnCode',
		description: '交易代码',
		example: '',
		type: 'String',
	},
	{
		key: 'txnStaCd',
		description: '交易状态码',
		example: '2',
		enum: ENUMS.transactionStatusCodeEnum,
		type: 'String',
	},
	{
		key: 'txnID',
		description: '交易编号',
		example: '',
		type: 'String',
	},
	{
		key: 'txnId',
		description: '交易编号',
		example: '',
		type: 'String',
	},
	{
		key: 'txnAt',
		description: '转账金额 [单位：分]',
		example: '',
		type: 'String',
	},
	{
		key: 'transactionAmount',
		description: '交易金额',
		example: '',
		type: 'String',
	},
	{
		key: 'txnAmt',
		description: '交易金额',
		example: '',
		type: 'String',
	},
	{
		key: 'txnCurrency',
		description: '交易货币',
		example: '840 (USD Code)',
		type: 'String',
	},
	{
		key: 'transactionCurrency',
		description: '交易货币',
		example: '',
		type: 'String',
	},
	{
		key: 'txnType',
		description: '交易类型',
		example: 'B0030',
		enum: ENUMS.txnTypeEnum,
		type: 'String',
	},
	{
		key: 'txnCategory',
		description: '交易类型',
		example: '5',
		enum: ENUMS.txnCategoryEnum,
		type: 'String',
	},
	{
		key: 'transactionCategory',
		description: '交易类型',
		example: '',
		value: VALUES.transactionCategoryValue,
		type: 'String',
	},
	{
		key: 'originalTransactionAmount',
		description: '原始交易余额',
		example: '',
		type: 'String',
	},
	{
		key: 'originalTxnAmt',
		description: '原始交易余额',
		example: '',
		type: 'String',
	},
	{
		key: 'originalTxnAt',
		description: '原始交易余额',
		example: '',
		type: 'String',
	},
	{
		key: 'originalTxnCurrency',
		description: '原始交易币种',
		example: '',
		type: 'String',
	},
	{
		key: 'originalTransactionCurrency',
		description: '原始交易币种',
		example: '',
		type: 'String',
	},
	{
		key: 'transactionType',
		description: '交易类型',
		example: '',
		value: VALUES.transactionTypeValue,
		type: 'String',
	},
	{
		key: 'transactionId',
		description: '交易ID',
		example: '',
		type: 'String',
	},
	{
		key: 'usdAmt',
		description: '美元金额 [单位分]',
		example: '',
		type: 'String',
	},
	{
		key: 'usdCurrencyCode',
		description: '美元币种',
		example: '',
		type: 'String',
	},
	{
		key: 'currencyCode',
		description: '币种',
		example: '',
		type: 'String',
	},
	{
		key: 'externalTxnRefId',
		description: '外部的交易参考号',
		example: '',
		type: 'String',
	},
	{
		key: 'payOrderInfo',
		description: '订单信息',
		example: '',
		type: 'String',
	},
	{
		key: 'discountDetails',
		description: '优惠信息',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: ['discountAmt', 'discoundNote', 'discountType'],
	},
	{
		key: 'discountAmt',
		description: '优惠金额',
		example: '',
		type: 'String',
	},
	{
		key: 'discoundNote',
		description: '优惠备注',
		example: '',
		type: 'String',
	},
	{
		key: 'discountType',
		description: '优惠类型',
		example: '',
		type: 'String',
	},
	{
		key: 'userPaymentAmt',
		description: '用户支付金额',
		example: '',
		type: 'String',
	},
	{
		key: 'txnCcy',
		description: '交易币种',
		example: '840 (USD Code)',
		type: 'String',
	},
];
