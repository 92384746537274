export const CARD_LIMIT = [
	{
		key: 'consumeSingleMax',
		description: '单笔消费限额',
		example: '1000',
		type: 'String',
	},
	{
		key: 'consumeDayMaxNum',
		description: '消费日次数',
		example: '1000',
		type: 'String',
	},
	{
		key: 'consumeDayMax',
		description: '消费日限额',
		example: '1000',
		type: 'String',
	},
	{
		key: 'withdrawDayMax',
		description: '提现日限额',
		example: '1000',
		type: 'String',
	},
	{
		key: 'withdrawDayMaxNum',
		description: '提现日次数',
		example: '1000',
		type: 'String',
	},
	{
		key: 'withdrawSingleMax',
		description: '单笔提现限额',
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysConsumeSingleMax',
		description: '系统允许设置的最大单笔消费限额',
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysConsumeDayMaxNum',
		description: '系统允许设置的最大消费日次数',
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysConsumeDayMax',
		description: '系统允许设置的最大消费日限额',
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysWithdrawDayMax',
		description: '系统允许设置的最大提现日限额',
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysWithdrawDayMaxNum',
		description: '系统允许设置的最大提现日次数',
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysWithdrawSingleMax',
		description: '系统允许设置的最大单笔提现限额',
		example: '1000',
		type: 'String',
	},
	{
		key: 'internationalRemitDayMax',
		description: '国际汇款日限额',
		example: '1000',
		type: 'String',
	},
	{
		key: 'internationalRemitDayMaxNum',
		description: '国际汇款日次数',
		example: '1000',
		type: 'String',
	},
	{
		key: 'internationalRemitSingleMax',
		description: '国际汇款单笔限额',
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysInternationalRemitDayMax',
		description: '系统允许设置的国际汇款日限额',
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysInternationalRemitDayMaxNum',
		description: '系统允许设置的国际汇款日次数',
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysInternationalRemitSingleMax',
		description: '系统允许设置的国际汇款最大单笔限额',
		example: '1000',
		type: 'String',
	},
];
