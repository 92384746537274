export const CARD_LIMIT = [
	{
		key: 'consumeSingleMax',
		description: 'Limit Amount of Single Purchase',
		example: '1000',
		type: 'String',
	},
	{
		key: 'consumeDayMaxNum',
		description: 'Limit Daily Number of Purchase',
		example: '1000',
		type: 'String',
	},
	{
		key: 'consumeDayMax',
		description: 'Limit Daily Purchase Amount',
		example: '1000',
		type: 'String',
	},
	{
		key: 'withdrawDayMax',
		description: 'Limit Daily Withdrawal Amount',
		example: '1000',
		type: 'String',
	},
	{
		key: 'withdrawDayMaxNum',
		description: 'Limit Daily Number of Withdrawal',
		example: '1000',
		type: 'String',
	},
	{
		key: 'withdrawSingleMax',
		description: 'Limit Amount of Single Withdrawal',
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysConsumeSingleMax',
		description: "System's Limit Amount of Single Purchase",
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysConsumeDayMaxNum',
		description: "System's Limit Daily Number of Purchase",
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysConsumeDayMax',
		description: "System's Limit Daily Purchase Amount",
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysWithdrawDayMax',
		description: "System's Limit Daily Withdrawal Amount",
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysWithdrawDayMaxNum',
		description: "System's Limit Daily Number of Withdrawal",
		example: '1000',
		type: 'String',
	},
	{
		key: 'sysWithdrawSingleMax',
		description: "System's Limit Amount of Single Withdrawal",
		example: '1000',
		type: 'String',
	},
	{
		key: 'internationalRemitDayMax',
		description: "System's Limit Daily International Remittance Amount",
		example: '1000',
		type: 'String',
	},
	{
		key: 'internationalRemitDayMaxNum',
		description: "System's Limit Daily Number of International Remittances",
		example: '1000',
		type: 'String',
	},
	{
		key: 'internationalRemitSingleMax',
		description: "System's Limit Amount of Single International Remittance",
		example: '1000',
		type: 'String',
	},
	{
		key: "sysInternationalRemitDayMax",
		description: "System's Allowed Daily Limit for International Remittances",
		example: "1000",
		type: "String"
	},
	{
		key: "sysInternationalRemitDayMaxNum",
		description: "System's Allowed Daily Number of International Remittances",
		example: "1000",
		type: "String"
	},
	{
		key: "sysInternationalRemitSingleMax",
		description: "System's Allowed Maximum Amount for a Single International Remittance",
		example: "1000",
		type: "String"
	}
];
