import { HTTP_METHODS, TAG } from '@/const';

export const INTERNAL_TRANSFER = [
	{
		id: '4101',
		title: 'Top Up',
		titleCN: '充值',
		endpoint: '/app/topUp',
		method: HTTP_METHODS.POST,
		tag: TAG.INTERNAL_TRANSFER,
		attributes: [
			'amt',
			'appliOpr',
			'checkOpr',
			'elecAppNo',
			'externalTxnRefId',
			'keyId',
			'note',
			'prdtNo',
			'topUpType',
			'transferDate',
			'transferNo',
			'transferTime',
			'transfereeInfo',
			'transfererInfo',
		],
		optionalAttributes: [
			'elecAppNo',
			'externalTxnRefId',
			'transferDate',
			'transferNo',
			'transferTime',
			'transfereeInfo',
			'transfererInfo',
		],
		respAttributes: [
			'amt',
			'externalTxnRefId',
			'originalTxnAmt',
			'originalTxnCurrency',
			'rechargeId',
			'referralCode',
			'settledWithCustomerCurrency',
			'totFee',
		],
	},
	{
		id: '4102',
		title: 'Transfer',
		titleCN: '转账',
		endpoint: '/app/cardTransfer',
		method: HTTP_METHODS.POST,
		tag: TAG.INTERNAL_TRANSFER,
		attributes: [
			'custId',
			'externalTxnRefId',
			'keyIdIN',
			'keyIdINType',
			'keyIdOUT',
			'note',
			'prdtNoIN',
			'prdtNoOUT',
			'txnAt',
			'txnCcy',
		],
		optionalAttributes: ['externalTxnRefId', 'note', 'prdtNoIN', 'txnCcy'],
		respAttributes: [
			'acctIdIN',
			'acctIdOUT',
			'acctNmIN',
			'acctNmOUT',
			'cardBrhIdIN',
			'cardBrhIdOUT',
			'cardBrhNmIN',
			'cardBrhNmOUT',
			'cardIdIN4',
			'cardIdOUT4',
			'currAvailAtOUT',
			'currBalAtOUT',
			'endDtOUT',
			'externalTxnRefId',
			'fee',
			'feeCurrency',
			'intTxnRefId',
			'intTxnSeqId',
			'lockAtOUT',
			'note',
			'originalTxnAmt',
			'originalTxnCurrency',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'state',
			'txnDt',
			'txnTm',
			'txnType',
		],
	},
];
